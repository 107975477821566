<template>
	<div ref="imageContainer" v-resize="calculateGridView">
		<div class="gridRow" v-for="(row, index) in GridView" :key="index" :ref="`gridRow_${index}`">
			<div class="item" v-for="(item, index) in row.items" :key="index" :style="{
					'flex-basis': (100 * item.aspectRatio) / row.totalAspectRatio + '%',
				}">
				<slot name="cell" v-if="item.file" :file="item.file" :index="Files.indexOf(item.file)">
				</slot>
				<div v-else></div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			GridView: [],
		};
	},
	props: {
		Files: {
			type: Array,
		},
		targetHeight: {
			type: Number,
			default: 200,
		},
	},
	computed: {
		...mapGetters(["SasToken"]),
	},
	watch: {
		Files: {
			immediate: true,
			handler: async function (value) {
				// this.$log.debug("watch Files");
				this.calculateGridView();
			},
		},
		targetHeight: {
			handler: async function (value) {
				this.calculateGridView();
			},
		},
	},
	mounted() {
		// this.$nextTick(() => {
		// 	this.calculateGridView();
		// });

		this.$log.debug("PhotoGrid loaded");
	},

	methods: {
		calculateGridView() {
			this.GridView = [];
			// this.$log.debug("calculateGridView");
			if (!this.$refs.imageContainer) {
				this.$log.debug("imageContainer not available");
				return;
			}

			// this.$log.debug(this.$refs.imageContainer);
			const clientWidth = this.$refs.imageContainer.clientWidth;

			// this.$log.debug("calculateGridView clientWidth:" + clientWidth);

			if (clientWidth === 0) return;

			const targetHeight = this.targetHeight;

			let gridRow = {
				items: [],
				totalAspectRatio: 0,
			};
			let currentTotalWidth = 0;

			this.Files.forEach((file) => {
				const aspectRatio = parseFloat(
					(file?.Metadata?.AspectRatio || file?.AspectRatio) ?? "1.2"
				);

				const w = targetHeight * aspectRatio;

				if (currentTotalWidth + w > clientWidth) {
					this.addRowToGrid(gridRow, targetHeight, clientWidth);
					gridRow = {
						items: [],
						totalAspectRatio: 0,
					};
					currentTotalWidth = 0;
				}
				gridRow.items.push({ file, aspectRatio });
				gridRow.totalAspectRatio += aspectRatio;
				currentTotalWidth += w;
			});

			if (gridRow.items.length > 0) {
				this.addRowToGrid(gridRow, targetHeight, clientWidth);
			}
		},

		addRowToGrid(gridRow, targetHeight, clientWidth) {
			const baseWidth = gridRow.totalAspectRatio * targetHeight;
			const estimatedHeight = targetHeight * (clientWidth / baseWidth);

			// this.$log.debug("clientWidth: " + clientWidth);
			// this.$log.debug("totalAspectRatio: " + gridRow.totalAspectRatio);
			// this.$log.debug("targetHeight: " + targetHeight);
			// this.$log.debug("estimatedHeight: " + estimatedHeight);

			// height cannot exeed 20% of target
			const maxHeight = targetHeight * 1.2;

			// this.$log.debug("maxHeight: " + maxHeight);
			if (maxHeight < estimatedHeight) {
				// if we need a placholder, enlarge items by 60%
				const placeholderAspectRatio =
					(clientWidth - gridRow.totalAspectRatio * maxHeight) / maxHeight;

				gridRow.items.push({
					file: null,
					aspectRatio: placeholderAspectRatio,
				});
				gridRow.totalAspectRatio += placeholderAspectRatio;

				// this.$log.debug("placeholder added:" + placeholderAspectRatio);
			}

			this.GridView.push(gridRow);
		},
	},
};
</script>
<style lang="scss" scoped>
.gridRow {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 6px;

	.item {
		margin-right: 6px;
		flex-shrink: 1;
		flex-grow: 0;
		&:last-child {
			margin-right: 0px;
		}
	}
}
</style>
