<template>
	<v-icon :color="IconColor" :size="size"> {{ this.Icon }}</v-icon>
</template>
<script>
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";

export default {
	name: "ApproveStatusIcon",
	props: {
		ApproveStatus: { type: Number, required: true },
		size: { type: Number, default: 18 }
	},
	computed: {
		IconColor() {
			switch (this.ApproveStatus) {
				case SgpApprovalOfferItemStatusEnum.APPROVED:
					return "green";
				case SgpApprovalOfferItemStatusEnum.REJECTED:
					return "error";
				case SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED:
					return "orange accent-3";
				case SgpApprovalOfferItemStatusEnum.NOT_SET:
				default:
					return "orange lighten-2";
			}
		},
		Icon() {
			switch (this.ApproveStatus) {
				case SgpApprovalOfferItemStatusEnum.APPROVED:
					return "fas fa-check-circle";
				case SgpApprovalOfferItemStatusEnum.REJECTED:
					return "fas fa-times-circle";
				case SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED:
					return "fas fa-edit";
				case SgpApprovalOfferItemStatusEnum.NOT_SET:
				default:
					return "fas fa-question-circle";
			}
		}
	}
};
</script>
