<template>
	<div style="position: relative; transition: 0.3s">
		<div style="position: absolute; z-index: 2; bottom: 10px; left: 10px">
			<v-icon color="grey">fab fa-vimeo-v</v-icon>
		</div>
		<v-img
			aspect-ratio="1.2"
			class="grey darken-4 rounded"
			:src="src"
			:alt="alt"
			@click="$emit('click')"
		>
		</v-img>
	</div>
</template>
<script>
export default {
	props: {
		src: {
			type: String,
		},
		alt: {
			type: String,
		},
	},
};
</script>
